
import { defineComponent } from "vue";
import { getaccountlist } from "@/api/common";
import { vGetPackageType} from '@/api/dashboard.js';

import { getCurrentUser, setCurrentUser} from "@/utils/index"

export default defineComponent({
  name: "accountlist-menu",
  components: {},
  data() {
    return {
      accountlist: [],
      currentUsid: "",
      isBibo : false
    }
  },
  setup() {  
    return { 
       
    };
  },
  methods: {
    async getAccountList() {
      const response = await getaccountlist();
        let self = this;
        if(response.data !== undefined && response.data.accounts !== undefined) {

          if(localStorage.getItem("pt") == 'bibo'){
            this.isBibo = true;
          } else {
            this.isBibo = false;
          }

          self.accountlist = response.data.accounts;

           
        }
    },
    async vGetPackageType(){
			const response = await vGetPackageType(); 
			if(response.data !== undefined ) {
        localStorage.setItem("pVaAcNkPaOgGeItRyEpAeL", response.data.packageType);
        setTimeout(() => {
              window.location.reload();
          }, 1000);
			}
		},

    changeAccount(a){
        let user = getCurrentUser();
        user.usid = a;
        setCurrentUser(user);
        localStorage.ec_current_usid = a;

        this.vGetPackageType();
          
    }
    
  },
  mounted() {
    
      let user = getCurrentUser();

      this.getAccountList();

      this.currentUsid = user.usid;
      
  }
});
