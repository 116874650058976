
import { defineComponent } from "vue";

export default defineComponent({
  name: "advisory-banner",
  components: {
    
  },
  data() {
    return {
      
    }
  },
  setup() {  
    return { 
       
    };
  },
  methods: {
  },
  mounted() {
      
  }
});
